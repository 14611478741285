export const environment = {
  production: true
};

export const apiHost: string = 'q2wueaat8c.execute-api.us-east-1.amazonaws.com'
export const apiUrl: string = 'https://q2wueaat8c.execute-api.us-east-1.amazonaws.com/production/api';
export { tinyMceApiKey } from './environment.dev';

export const cognitoConfig = {
  aws_cognito_region: "us-east-1",
  aws_user_pools_id:  "us-east-1_BZhlDRurq",
  aws_user_pools_web_client_id: "194ss7p14pspu7aq7u7fcavhbt",
  aws_cognito_identity_pool_id: "us-east-1:02ef238e-63a6-486e-9ed9-bd0a076ee768",
  API: {
    // TODO: dynamically create these endpoints from given environment
    endpoints: [
        {
            name: "prod-interview-api",
            endpoint: apiUrl
        },
    ]
  }
};
