<mat-dialog-content>
    <div class='message'>{{ this.data.message }}</div>
    <div>
        <ul *ngFor="let item of data.affectedItems">
            <li>{{ item }}</li>
        </ul>
    </div>
    
    <div class='confirmation'>Do you want to proceed?</div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="true">Yes</button>
    <button mat-stroked-button [mat-dialog-close]="false" cdkFocusInitial>No</button>
</mat-dialog-actions>
