<div fxLayout fxLayoutAlign = "left">
  <mat-form-field fxFlex="20%">
    <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Filter" />
  </mat-form-field>
</div>
<div class="mat-elevation-z2">
  <div class="table-container">
    <table mat-table [dataSource]="questions" matSort>
        <ng-container matColumnDef="QuestionID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let question"> {{question.QuestionID}} </td>
        </ng-container>

        <ng-container matColumnDef="QuestionName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let question">
                {{question.QuestionName.length <= 25 ? question.QuestionName : question.QuestionName.slice(0, 26) + '...'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="QuestionDescription">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let question">
                {{question.QuestionDescription.length <= 40 ? question.QuestionDescription : question.QuestionDescription.slice(0, 41) + '...'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="QuestionTypeName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let question"> {{question.QuestionType.QuestionTypeName}} </td>
        </ng-container>

        <ng-container matColumnDef="Actions" sticky>
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let question">
                <button style="margin-right:5px;" mat-stroked-button (click)="onAction(question, 'view')">View</button>
                <button style="margin-right:5px;" mat-stroked-button (click)="onAction(question, 'edit')">Edit</button>
                <button mat-stroked-button (click)="onAction(question, 'delete')">Delete</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedQuestionData; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedQuestionData;"></tr>
    </table>
  </div>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>
</div>
