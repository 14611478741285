import { Component, OnInit } from '@angular/core';
import { CognitoService } from 'src/app/shared/services/cognito/cognito.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  userIsLoggedIn = false;

  constructor(
    private cognitoService: CognitoService
  ) { }

  ngOnInit() {
    this.cognitoService.isLoggedIn$.subscribe(
      isLoggedIn => {
        this.userIsLoggedIn = isLoggedIn;
        if (!isLoggedIn) {
          this.cognitoService.isLoggedIn().subscribe(
            () => this.userIsLoggedIn = true,
            () => this.userIsLoggedIn = false
          )
        }
      }
    )

  }



}

