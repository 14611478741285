import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ImpactConfirmationDialogData {
  message: string,
  affectedItems: string[]
}

@Component({
  selector: 'app-impact-confirmation-dialog',
  templateUrl: './impact-confirmation-dialog.component.html',
  styleUrls: ['./impact-confirmation-dialog.component.css']
})
export class ImpactConfirmationDialogComponent {

  affectedItems: String[] = [];

  constructor(
    public dialogRef: MatDialogRef<ImpactConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImpactConfirmationDialogData

  ) { }


}
