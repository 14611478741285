import { Injectable } from '@angular/core';
import { CognitoService } from '../cognito/cognito.service';
import { UserSettings } from 'src/app/modules/users/models/userSettings.interface';
import { QueryOptions } from 'src/app/shared/models/models.index';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, map, catchError} from 'rxjs/operators';
import { apiUrl } from 'src/environments/environment';
import { createParams } from 'src/app/shared/helpers/createParams';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';
import { CognitoUser } from 'amazon-cognito-identity-js';

interface Resource {
  OrganizationID?: number,
  CreatedByUser?: string | null,
  EditedByUser?: string | null,
  DeletedByUser?: string | null,
}

interface ResourceProps {
  queryOptions?: Partial<QueryOptions>,
  resourceId?: number
}

export enum ResourceType {
  ASSESSMENT = 'assessments',
  INVITATION = 'invitations',
  QUESTION = 'questions',
  REVIEW = 'reviews',
  REVIEWER = 'reviewers',
  ROLE = 'roles',
  ROTATION = 'rotations'
}

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  constructor(
    private cognitoService: CognitoService,
    private http: HttpClient,
    private httpError: HttpErrorService,
  ) { }

  private getCurrentUserAndUserSettings(): Observable<[CognitoUser, UserSettings]> {
    return combineLatest([this.cognitoService.getUser(), this.cognitoService.getCurrentUserSettings()]);
  }

  createResource(resource: Resource, resourceType: ResourceType, guest?: boolean): Observable<any> {
    let appendUser;
    // If we are creating a resource with a guest logged in use 'SYSTEM' as the username for any audit field related data
    // For now, any time we call create resource from a guest perspective the passed in "resource" already has an orgID on it so 
    // we do not need to assign it as is done in the else block
    if (guest) {
      appendUser = () => {
        resource.CreatedByUser = 'SYSTEM';
        resource.EditedByUser = 'SYSTEM';
        return of(resource);
      }
    } else {
      appendUser = () => this.getCurrentUserAndUserSettings().pipe(
        map(([currentUser, userSettings]) => {
          resource.CreatedByUser = currentUser.getUsername();
          resource.EditedByUser = currentUser.getUsername();
          if (userSettings.OrganizationID) {
            resource.OrganizationID = userSettings.OrganizationID;
          }
          return resource;
        }) 
      );
    }

    const url =`${apiUrl}/${resourceType}`;
    const makeHttpRequest = () => (source: Observable<Resource>):Observable<any> => source.pipe(
      switchMap(resource => this.http.post<any>(url, resource).pipe(
        catchError(err => this.httpError.handleError(err))))
    );

    return appendUser().pipe(makeHttpRequest());
  }

  readResource(resourceType: ResourceType, resourceProps?: ResourceProps): Observable<any> {
    // If on resourceProps.queryOptions there exists an organizationID skip getting user settings for org id and grab from resource
    let appendUser;
    if(resourceProps?.queryOptions?.organizationID) {
      appendUser = () => {
        let params = resourceProps?.queryOptions ? createParams(resourceProps.queryOptions) : new HttpParams();
        return of(params);
      }
    } else {
      appendUser = () => this.getCurrentUserAndUserSettings().pipe(
        map(([_, userSettings]) => {
          let params = resourceProps?.queryOptions ? createParams(resourceProps.queryOptions) : new HttpParams();
          if (userSettings.OrganizationID) {
            params = params.append('organizationID', userSettings.OrganizationID!.toString());
          }
          return params;
        })
      );
    }

    const url = () => {
      if (resourceProps?.resourceId) {
        return `${apiUrl}/${resourceType}/${resourceProps.resourceId}`;
      } else {
        return `${apiUrl}/${resourceType}`;
      }
    };
    
    const makeHttpRequest = () => (source: Observable<HttpParams>): Observable<any> => source.pipe(
      switchMap(params => this.http.get<any>(url(), {params}).pipe(catchError(err => this.httpError.handleError(err)))
      )
    );

    return appendUser().pipe(makeHttpRequest());
  }

  updateResource(id: number, resource: Resource, resourceType: ResourceType): Observable<any> {
    const appendUser = () => this.getCurrentUserAndUserSettings().pipe(
      map(([currentUser, userSettings]) => {
        resource.EditedByUser = currentUser.getUsername();
        if (userSettings.OrganizationID) {
          resource.OrganizationID = userSettings.OrganizationID;
        }
        return resource;
      })
    );

    const url = `${apiUrl}/${resourceType}/${id}`;
    const makeHttpRequest = () => (source: Observable<Resource>): Observable<any> => source.pipe(
      switchMap(resource => this.http.put<any>(url, resource).pipe(
        catchError(err => this.httpError.handleError(err))))
    );

    return appendUser().pipe(makeHttpRequest());
  }

  deleteResource(id: number, resourceType: ResourceType): Observable<any> {
    const appendUser = () => this.getCurrentUserAndUserSettings().pipe(
      map(([currentUser, userSettings]) => {
        let params = new HttpParams();
        params = params.append('user', currentUser.getUsername());
        params = params.append('organizationID', userSettings.OrganizationID!.toString());
        return params;
      })
    );

    const url = `${apiUrl}/${resourceType}/${id}`;
    const makeHttpRequest = () => (source: Observable<HttpParams>): Observable<any> => source.pipe(
      switchMap(params => this.http.delete<any>(url, {params}).pipe(catchError(err => this.httpError.handleError(err)))
      )
    );

    return appendUser().pipe(makeHttpRequest());
  }
}

