<h1>Notifications</h1>
<table mat-table [dataSource]="notificationEvents.slice(0, maxNotificationsShown)" id="notifications_table">
    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef id="th_message">New</th>
        <td
            mat-cell
            *matCellDef="let notificationEvent; let i = index"
            [id]="'td_message_' + i"
            [style]="notificationEvent.NotificationEventIsRead ? '' : 'font-weight: bold'"
        >
            {{ isModal ? notificationEvent.NotificationEventShort : notificationEvent.NotificationEventLong }}
        </td>
    </ng-container>
    <ng-container matColumnDef="timeSinceCreation">
        <th mat-header-cell *matHeaderCellDef id="th_timeSinceCreation"></th>
        <td
            mat-cell
            *matCellDef="let notificationEvent; let i = index"
            [id]="'td_timeSinceCreation_' + i"
        >
            {{ timesSinceNotificationCreations[i] + ' ago' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="markAsRead">
        <th mat-header-cell *matHeaderCellDef id="th_markAsRead">Mark as read</th>
        <td
            mat-cell
            *matCellDef="let notificationEvent; let i = index"
            [id]="'td_markAsRead_' + i"
        >
        <button
            mat-button
            *ngIf="!notificationEvent.NotificationEventIsRead"
            (click)="onMarkAsRead(i)"
        >
        <fa-icon
            [icon]="checkIcon"
        ></fa-icon>
        </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedNotificationEventData"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedNotificationEventData"></tr>    
</table>
<div class="notifications-table__container--actions">
    <button 
        *ngIf="isModal" 
        mat-button 
        matDialogClose 
        [routerLink]="['/notifications']"
    >See all</button>
    <button 
        mat-button
        (click)="onMarkAllAsRead()"
    >Mark all as read</button>
</div>