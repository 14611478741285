import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AssessmentsBuilderService } from 'src/app/modules/assessments-builder/assessments-builder.service';
import { QuestionsBuilderService } from 'src/app/modules/questions-builder/questions-builder.service';
import { ImpactConfirmationDialogComponent, ImpactConfirmationDialogData } from '../components/impact-confirmation-dialog/impact-confirmation-dialog.component';
import { QueryOptions } from 'src/app/shared/models/models.index';
import { map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { InvitationsBuilderService } from 'src/app/modules/invitations-builder/invitations-builder.service';

// Possible options for types of records than can be updated and need to use this service to warn about impacts
export enum UpdatingRecordTypes {
  ASSESSMENT,
  QUESTION
};

/**
 *  Possible actions that trigger this service being called. The displayed message is dependent on both the type of
 *  record being changed as well as the action that has been triggered.
 */
export enum Actions {
  EDIT,
  DELETE
}

/**
 * Defines the signature of a function that can be used to query impacted records.
 * This is generic because different functions will be called for different record types.
 */
type QueryFunctionType = (queryOptions: QueryOptions) => Observable<any[]>;

/**
 * Template for the fields that must be extracted from the impacted record results for displaying.
 * Ex: If the impacted records are assessments, then we must extract AssessmentID and AssessmentName
 */
interface ExtractedFieldNames {
  id: string,
  name: string
};

@Injectable({
  providedIn: 'root'
})
export class ConfirmActionService {

  /**
   * So far this service is only being utilized for the updating/deleting of questions and assessments,
   * but it is possible that other records types could be added later. Below are the steps required to add a new
   * record type.
   *
   * - Add the name of the record that will be updated to the UpdatingRecordTypes enum.
   * - Import the service that performs the API queries, and inject the service in the constructor of this class.
   * - Add a case to the switch statement in the getConfirmation method.
   * - Set the queryOptions object with the ID of the record being updated.
   * - Set the queryFunction to the function in the service that queries for impacted records.
   * - Set the service property to the service that will be used
   * - Set the fields that need to be extracted from the impacted records.
   * - Set the message that will be displayed depending on the action that has triggered this method
   *
   */

  // Argument to send to API query function
  queryOptions = <QueryOptions>{};

  // Function that will be used to query impacted records
  queryFunction : QueryFunctionType = (queryOptions: QueryOptions) =>  of([]);

  // The type of service to be used
  service : any  = null;

  // Fields we will extract from impacted records for displaying the confirmation dialog
  fieldsToExtract = <ExtractedFieldNames>{ id: "", name: ""};

  // Skeleton for the injected data to be sent to the confirmation dialog component
  data: ImpactConfirmationDialogData = { message: "", affectedItems: []};

  // Additional configuration for dialog component
  dialogConfig = { width: '400px', data: this.data };

  constructor(
    private assessmentsBuilderService: AssessmentsBuilderService,
    private invitationsBuilderService: InvitationsBuilderService,
    public impactConfirmationDialog: MatDialog
    ) {}

  public getConfirmation( typeOfUpdatingRecord: UpdatingRecordTypes, action: Actions, recordID: number) : Observable<boolean> {

    switch(typeOfUpdatingRecord) {
      case UpdatingRecordTypes.QUESTION:
        // Set parameters for API query
        this.queryOptions.questionID = recordID;

        // Set API query function
        this.queryFunction = this.assessmentsBuilderService.getAssessments;
        this.service = this.assessmentsBuilderService;

        // Set field names that we need to extract from results
        this.fieldsToExtract.id = 'AssessmentID';
        this.fieldsToExtract.name = 'AssessmentName';

        // Set message for confirmation dialog
        this.data.message = action === Actions.EDIT ?
            'This question is a part of an existing assessment(s). Saving the question will update the following assessment(s):' :
            'This question is a part of an existing assessment(s). Deleting the question will remove it from the following assessment(s):';

        break;
      case UpdatingRecordTypes.ASSESSMENT:
        // Set parameters for API query
        this.queryOptions.assessmentID = recordID;

        // Set API query function
        this.queryFunction = this.invitationsBuilderService.getInvitationsByAssesmentID;
        this.service = this.invitationsBuilderService;

        // Set field names that we need to extract from results
        this.fieldsToExtract.id = 'InvitationID';
        this.fieldsToExtract.name ='CandidateName';

        // Set message for confirmation dialog
        this.data.message = action === Actions.EDIT ?
          'This assessment is a part of an existing invitation(s). Saving the assessment will update the following invitation(s):' :
          'This assessment is a part of an existing invitation(s). Deleting the assessment will remove it from the following invitation(s):';

        break;
      default:
        throw new Error('Invalid record type.');
    }
    return this.performQuery();
  }

  private performQuery() : Observable<boolean> {
    return this.queryFunction.call(this.service, this.queryOptions)
    .pipe(
      // Convert the observable list of affected records to an observable list of strings that can be displayed
      map( (affectedRecords: any[]) => affectedRecords.map( (record: any) => `${record[this.fieldsToExtract.id]} ${record[this.fieldsToExtract.name]}`)),

      // Switch from the API observable to listen to the response from the confirmation dialog
      switchMap( (affectedRecords: string[]): Observable<boolean> => {

        // If no records were received in the query, there will be no impacts, so go ahead and return true and bypass the confirmation dialog
        if( !(affectedRecords.length > 0) ) {
          return of(true);
        } else {
          // Finish initializing data object
          this.data.affectedItems = affectedRecords;

          // Finish initializing configuration object for confirmation dialog
          this.dialogConfig.data = this.data;

          // Open dialog with injected data and return an observable to the component
          const dialogRef = this.impactConfirmationDialog.open(ImpactConfirmationDialogComponent, this.dialogConfig);

          return dialogRef.afterClosed() as Observable<boolean>
        }
      })
    );
  }
}
