import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { version } from '../../../../../package.json';

/* Third party */
import {
  faBars,
  faBell,
  faCog,
  faQuestionCircle,
  faSignInAlt,
  faSignOutAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { CognitoService } from '../../services/cognito/cognito.service';
import { SnackbarHandlerService } from '../../services/snackbar-handler.service';
import { NotificationsManagerService } from '../../../modules/notifications-manager/notifications-manager.service';
import { NotificationsModalComponent } from '../../../modules/notifications-manager/components/notifications-modal/notifications-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { map, reduce, filter, mergeMap, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotificationEvent } from '../../models/models.index';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  displayedNotificationCount: string = '';
  menuBars = faBars;
  notificationsIcon = faBell;
  supportIcon = faQuestionCircle;
  settingsIcon = faCog;
  signInIcon = faSignInAlt;
  signOutIcon = faSignOutAlt;
  usersIcon = faUsers;
  userIsLoggedIn = false;
  display = 'flex';
  version: string = version;

  constructor(
    private cognitoService: CognitoService,
    private snackbarService: SnackbarHandlerService,
    private notificationsManagerService: NotificationsManagerService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.cognitoService.isLoggedIn$
        .pipe(
          tap((isLoggedIn) => {
            this.userIsLoggedIn = isLoggedIn;
            if (!isLoggedIn) {
              this.cognitoService.isLoggedIn().subscribe(
                () => (this.userIsLoggedIn = true),
                () => (this.userIsLoggedIn = false)
              );
            }
          }),
          mergeMap(() => this.cognitoService.getUser()),
          mergeMap((userCreds) =>
            this.notificationsManagerService.getNotificationEvents({
              userID: userCreds.username,
            })
          ),
          tap(
            (notificationEvents) =>
              (this.displayedNotificationCount =
                this.generateNotificationBadgeCount(notificationEvents))
          )
        )
        .subscribe()
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter((evt) => evt instanceof NavigationEnd),
          map((evt: any) => evt.url)
        )
        .subscribe((url: string) => {
          if (url.includes('take-assessment')) {
            this.display = 'none';
          } else {
            this.display = 'flex';
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onShowNotifications(): void {
    const dialogRef = this.dialog.open(NotificationsModalComponent, {
      width: '500px',
    });
    this.subscription.add(
      dialogRef
        .afterClosed()
        .pipe(
          mergeMap(() => this.cognitoService.getUser()),
          mergeMap((userCreds) =>
            this.notificationsManagerService.getNotificationEvents({
              userID: userCreds.username,
            })
          ),
          tap(
            (notificationEvents) =>
              (this.displayedNotificationCount =
                this.generateNotificationBadgeCount(notificationEvents))
          )
        )
        .subscribe()
    );
  }

  generateNotificationBadgeCount(
    notificationEvents: NotificationEvent[]
  ): string {
    const totalUnreadNotificationEvents =
      notificationEvents.filter((ne) => !ne.NotificationEventIsRead).length ||
      0;
    if (totalUnreadNotificationEvents > 9) return '9+';
    else if (totalUnreadNotificationEvents > 0)
      return `${totalUnreadNotificationEvents}`;
    else return '';
  }

  signOut() {
    this.cognitoService.signOut().subscribe(
      () => {
        this.snackbarService.openSnackBar('Signed out successfully');
        window.location.reload();
      },
      () =>
        this.snackbarService.openSnackBar(
          'An error occurred while signing out '
        )
    );
  }
}
