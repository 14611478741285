import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { apiUrl } from 'src/environments/environment';
import { Assessment, AssessmentType, QueryOptions } from 'src/app/shared/models/models.index';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';
import { UserActivityService, ResourceType } from 'src/app/shared/services/user-activity/user-activity.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentsBuilderService {

  constructor(
    private http: HttpClient,
    private httpError: HttpErrorService,
    private userActivity: UserActivityService,
  ) { }

  getAssessmentTypes(): Observable<AssessmentType[]> {
    return this.http.get<any>(`${apiUrl}/assessment-types`).pipe(catchError(err => this.httpError.handleError(err)));
  }

  getAssessment(id: number, queryOptions?: Pick<QueryOptions, 'includeDeleted'>): Observable<Assessment> {
    return this.userActivity.readResource(ResourceType.ASSESSMENT, { resourceId: id, queryOptions });
  }

  getAssessments(queryOptions?: Pick<QueryOptions,'includeInvalid'>): Observable<Assessment[]> {
    return this.userActivity.readResource(ResourceType.ASSESSMENT, { queryOptions });
  }

  getAssessmentsByQuestionID(queryOptions?: Pick<QueryOptions,'includeInvalid' | 'questionID'>): Observable<Assessment[]> {
    return this.userActivity.readResource(ResourceType.ASSESSMENT, { queryOptions });
  }

  updateAssessment(id: number, assessment: Assessment): Observable<any> {
    return this.userActivity.updateResource(id, assessment, ResourceType.ASSESSMENT);
  }

  saveAssessment(assessment: Assessment): Observable<any> {
    return this.userActivity.createResource(assessment, ResourceType.ASSESSMENT);
  }

  deleteAssessment(id: number): Observable<any> {
    return this.userActivity.deleteResource(id, ResourceType.ASSESSMENT);
  }
}
