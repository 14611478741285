<mat-sidenav-container class="sidenav__container" id="side">
	<mat-sidenav #sidenav mode="side"  opened>
    <div id="logo-wrapper">
      <img alt="Antage, Inc." id="logo" src="assets/images/antage.png" />
    </div>
		<mat-list>
			<mat-list-item class="side-nav__list-item">
				<button
					mat-stroked-button
					[routerLink]="['/']"
					class="styled side-nav__button"
					id="side-nav__button--home"
				>
					Home
				</button>
			</mat-list-item>
			<mat-list-item class="side-nav__list-item">
				<button
					mat-stroked-button
					[routerLink]="['/questions']"
					class="styled side-nav__button"
					id="side-nav__button--questions"
				>
					Questions
				</button>
			</mat-list-item>
			<mat-list-item class="side-nav__list-item">
				<button
					mat-stroked-button
					[routerLink]="['/assessments']"
					class="styled side-nav__button"
					id="side-nav__button--assessments"
				>
					Assessments
				</button>
			</mat-list-item>
			<mat-list-item class="side-nav__list-item">
				<button
					mat-stroked-button
					[routerLink]="['/invitations']"
					class="styled side-nav__button"
					id="side-nav__button--invitations"
				>
					Invitations
				</button>
			</mat-list-item>
			<mat-list-item class="side-nav__list-item">
				<button
					mat-stroked-button
					[routerLink]="['/reviews']"
					class="styled side-nav__button"
					id="side-nav__button--reviews"
				>
					Reviews
				</button>
			</mat-list-item>
		</mat-list>
	</mat-sidenav>
</mat-sidenav-container>
