import { Injectable } from '@angular/core';
import { apiUrl } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor(
    private http: HttpClient,
    private httpError: HttpErrorService,
  ) { }

  sendEmails(emailAddresses: string[], message: string, subject: string): Observable<any> {
    return this.http.post(`${apiUrl}/emails`, { emailAddresses, message, subject })
      .pipe(catchError(err => this.httpError.handleError(err)));
  }
}
