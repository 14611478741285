<mat-toolbar [ngStyle]="{'display': display}">
  <div class="left inner-wrapper"></div>
  <span>Antage Interview</span>

  <div *ngIf="!userIsLoggedIn" class="right inner-wrapper icons">
    <button mat-icon-button [routerLink]="['/login']">
      <fa-icon [icon]="signInIcon" size="2x"></fa-icon>
    </button>
  </div>

  <div *ngIf="userIsLoggedIn" class="right inner-wrapper icons">

    <button mat-icon-button [matMenuTriggerFor]="usersOptions">
      <fa-icon [icon]="usersIcon" size="2x"></fa-icon>
    </button>

    <mat-menu #usersOptions="matMenu">
      <button
        mat-menu-item
        [routerLink]="['/users']"
      >Manage Users</button>
      <button
        mat-menu-item
        [routerLink]="['/roles']"
      >Manage Roles</button>
      <button
        mat-menu-item
        [routerLink]="['/rotations']"
      >Manage Rotations</button>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="settingsPage">
      <fa-icon [icon]="settingsIcon" size="2x"></fa-icon>
    </button>

    <mat-menu #settingsPage="matMenu">
      <button mat-menu-item [routerLink]="['/account/settings']">My Account</button>
      <button mat-menu-item [routerLink]="['/notifications/options']">Manage notifications</button>
      <button mat-menu-item [routerLink]="['/account/change-password']">Change password</button>
    </mat-menu>

    <button
      mat-icon-button
      (click)="onShowNotifications()"
      class="notificationsIconContainer"
    >
      <fa-icon [icon]="notificationsIcon" size="2x"></fa-icon>
      <span *ngIf="displayedNotificationCount" class="notificationsCount">{{ displayedNotificationCount }}</span>
    </button>

    <mat-menu #notifications="matMenu">
      <button mat-menu-item>Notifications</button>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="contactSupport">
      <fa-icon [icon]="supportIcon" size="2x"></fa-icon>
    </button>

    <mat-menu #contactSupport="matMenu">
      <div id="version">v{{ version }}</div>
      <button mat-menu-item [routerLink]="['/account/contact-support']">Contact Support</button>
    </mat-menu>

    <button mat-icon-button (click)="signOut()" [routerLink]="['/login']">
      <fa-icon [icon]="signOutIcon" size="2x"></fa-icon>
    </button>
    

  </div>
</mat-toolbar>

