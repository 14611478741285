import {
  concatAll,
  toArray,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  Observable,
} from 'rxjs';

/* Makes logging/debugging easier */
export const log$ = (message = '') => (source: Observable<any>) => {
  return source.pipe(
    tap(x => console.log(message, x))
  )
}

/* Utitity method to wrap switchMap to avoid passing in an unused parameter */
export const switchMap$ = (switchedObservable: Observable<any>) => (source: Observable<any>) => {
  return source.pipe(switchMap(() => switchedObservable))
}

/* Utility method to flatten a stream of arrays into a single array */
export const flatten$ = () => (source: Observable<any>): Observable<any[]> => {
  return source.pipe(
    concatAll(),
    toArray()
  )
}
