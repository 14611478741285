<form *ngIf="data" class="questionsForm" [formGroup]="questionsForm">
  <h1 class="questionsFormHeader">{{ (data.action![0] | uppercase) + data.action!.slice(1) }} Question:</h1>

  <mat-form-field>
    <mat-label>
      Question Name:
    </mat-label>
    <input matInput type="text" formControlName="name" required />
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Question Description:
    </mat-label>
    <input matInput type="text" formControlName="description" required />
  </mat-form-field>

	<mat-form-field *ngIf="data.action !== 'create'" id="question_edited_datetime" readonly>
		<mat-label>Last Edited:</mat-label>
		<!-- note that this is a "fake" input hardcoded to disabled -->
		<input matInput type="text" [value]="data.question.EditedDateTime | date:'medium'" disabled>
	</mat-form-field>

	<mat-form-field *ngIf="data.action !== 'create'" id="question_edited_by" readonly>
		<mat-label>Edited by:</mat-label>
		<!-- note that this is a "fake" input hardcoded to disabled -->
		<input matInput type="text" [value]="editedByUserFullName" disabled>
	</mat-form-field>

  <div formArrayName="questionFiles">
    <div class="fileUploadContainer" *ngFor="let file of questionFiles.controls; let i = index">
      <mat-label>
        Add Attachment:
      </mat-label>
      <button mat-stroked-button (click)="fileInput.click()" [disabled]="readOnly">
        <span>{{ questionAttachments[i]?.QuestionAttachmentName ? questionAttachments[i].QuestionAttachmentName.substring(0, 10) + "..." : "Select" }}</span>
        <input
          #fileInput
          [formControlName]="i"
          type="file"
          (change)="onSelectFile($event, i)"
          style="display:none;" 
        />
      </button>
      <fa-icon *ngIf="!readOnly" [icon]="trashIcon" (click)="onRemoveFile(i)"></fa-icon>
      <fa-icon *ngIf="data.question?.QuestionAttachments" [icon]="downloadIcon" (click)="onDownloadFile(i)"></fa-icon>
    </div>
    <mat-hint>
      Upload up to two documents, 1MB max
    </mat-hint>
  </div>

  <mat-form-field>
    <mat-label>
      Question Type:
    </mat-label>
    <mat-select [compareWith]="compareQuestionTypes" formControlName="questionType" required>
      <mat-option
        *ngFor="let questionType of questionTypes"
        [value]="questionType"
        >{{ questionType.QuestionTypeName }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <!-- Code Editor & Code Runner -->
  <mat-form-field *ngIf="selectedQuestionType.QuestionTypeName === 'Code Editor' || selectedQuestionType.QuestionTypeName === 'Code Runner'">
    <mat-label>
      Available Languages:
    </mat-label>
    <mat-select [compareWith]="compareLanguages" formControlName="availableLanguages" multiple>
      <mat-option *ngFor="let language of allLanguages" [value]="language">{{ language.aliases[0] }}</mat-option>
    </mat-select>
  </mat-form-field>
  
  <mat-form-field *ngIf="selectedQuestionType.QuestionTypeName === 'Code Editor' || selectedQuestionType.QuestionTypeName === 'Code Runner'">
    <mat-label>
      Default Language:
    </mat-label>
    <mat-select [compareWith]="compareLanguages" formControlName="defaultLanguage">
      <mat-option
        *ngFor="let language of availableLanguages"
        [value]="language"
        >{{ !!language ? language.aliases[0] : null }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <div 
    *ngIf="selectedQuestionType.QuestionTypeName === 'Code Editor' || selectedQuestionType.QuestionTypeName === 'Code Runner'"
    formGroupName="editorRunner"  
  >
    <mat-accordion>
      <div formArrayName="codeSnippets" *ngFor="let snippet of codeSnippets.controls; let idx = index">
        <div [formGroupName]="idx">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-label>Code snippet for {{ snippet.value.languageAlias }}</mat-label>
              </mat-panel-title>
              <mat-panel-description>
                {{ snippet.value.code.length < 100 ? snippet.value.code : snippet.value.code.substring(0, 100) + '...' }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="monacoContainer">
              <ngx-monaco-editor [options]="editorOptions" formControlName="code" (init)="onEditorInit($event, idx)"></ngx-monaco-editor>
              <mat-hint>{{ snippet.value.code.length }} / {{ maxAnswerLen }}</mat-hint>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </mat-accordion>
    <mat-form-field id="plaintext_points_field">
      <input
        matInput
        type="number"
        placeholder="Points:" 
        formControlName="points" 
        [min]="minPointsAllowed" 
        [max]="maxPointsAllowed"
      >
    </mat-form-field>
  </div>

  <!-- Plain Text -->
  <div 
    *ngIf="selectedQuestionType.QuestionTypeName === 'Plain Text'"
    formGroupName="plainText"
    class="plainTextContainer"
  >
    <mat-label>Text Hint:</mat-label>
    <mat-form-field id="plaintext_answer_field">
      <textarea 
        matInput
        placeholder="Write your hint here" 
        [maxlength]="maxAnswerLen"
        formControlName="answer"
        #answer
      ></textarea>
      <mat-hint>{{ answer.value.length }} / {{ maxAnswerLen }}</mat-hint>
    </mat-form-field>
    <mat-form-field id="plaintext_points_field">
      <input
        matInput
        type="number"
        placeholder="Points:" 
        formControlName="points" 
        [min]="minPointsAllowed" 
        [max]="maxPointsAllowed"
      >
    </mat-form-field>  
  </div>
  
  <!-- Multi Choice & Multi Select -->
	<div formArrayName="multiOptions" *ngIf="selectedQuestionType.QuestionTypeName === 'Multi Choice' || selectedQuestionType.QuestionTypeName === 'Multi Select'">

    <!-- Label, up/down btns -->
    <mat-label>Answers:</mat-label>
    <mat-hint *ngIf="selectedQuestionType.QuestionTypeName === 'Multi Choice'">Candidate may select only one answer</mat-hint>
    <mat-hint *ngIf="selectedQuestionType.QuestionTypeName === 'Multi Select'">Candidate may select multiple answers</mat-hint>
    <div
      class="multiOptionsContainer"
      *ngFor="let option of multiOptions.controls; let i = index"
    >
      <div
        class="moveMultiOptionContainer"
        [id]="'move_multi_option_container_' + i"
      >
        <div
          *ngIf="
            this.readOnly || multiOptions.length === 1;
            then disabledMoveOptions;
            else enabledMoveOptions
          "
        ></div>
        <ng-template #disabledMoveOptions>
          <fa-icon
            [icon]="angleUpIcon"
            [id]="'angle_up_' + i"
            [styles]="{ opacity: '0.6', cursor: 'not-allowed' }"
          ></fa-icon>
          <fa-icon
            [icon]="angleDownIcon"
            [id]="'angle_up_' + i"
            [styles]="{ opacity: '0.6', cursor: 'not-allowed' }"
          ></fa-icon>
        </ng-template>
        <ng-template #enabledMoveOptions>
          <fa-icon
            [icon]="angleUpIcon"
            [id]="'angle_up_' + i"
            (click)="moveMultiOption('up', i)"
          ></fa-icon>
          <fa-icon
            [icon]="angleDownIcon"
            [id]="'angle_up_' + i"
            (click)="moveMultiOption('down', i)"
          ></fa-icon>
        </ng-template>
      </div>

      <!-- Answer option, point value, delete btn -->
      <div 
        class="multiOptionContainer" 
        [id]="'multi_option_' + i"
        [formGroupName]="i"  
      >
        <mat-form-field [id]="'multi_option_answer_field_' + i">
          <input 
            matInput 
            placeholder="Answer {{i + 1}}:" 
            formControlName="answer"
          >
        </mat-form-field>
        
        <mat-form-field [id]="'multi_option_points_field_' + i">
          <input 
            matInput 
            type="number" 
            placeholder="Points:" 
            formControlName="points" 
            [min]="minPointsAllowed" 
            [max]="maxPointsAllowed"
          >
        </mat-form-field>

        <div
          *ngIf="
            this.readOnly || multiOptions.length === 1;
            then disabledDeleteOptionButton;
            else enabledDeleteOptionButton
          "
        ></div>
        <ng-template #disabledDeleteOptionButton>
          <fa-icon
            [icon]="timesCircleIcon"
            [id]="'multi_options_times_icon_' + i"
            [styles]="{ opacity: '0.6', cursor: 'not-allowed' }"
          ></fa-icon>
        </ng-template>
        <ng-template #enabledDeleteOptionButton>
          <fa-icon
            [icon]="timesCircleIcon"
            [id]="'multi_options_times_icon_' + i"
            (click)="removeMultiOption(i)"
          ></fa-icon>
        </ng-template>
      </div>
    </div>

    <!-- Add option btn -->
    <div
    *ngIf="
      readOnly;
      then disabledAddOptionButton;
      else enabledAddOptionButton
    "
    ></div>
    <ng-template #disabledAddOptionButton>
      <fa-icon
        [icon]="plusCircleIcon"
        id="multi_options_plus_icon"
        [styles]="{ opacity: '0.6', cursor: 'not-allowed' }"
        [hidden]="readOnly"
        ></fa-icon>
      </ng-template>
      <ng-template #enabledAddOptionButton>
        <fa-icon
        [icon]="plusCircleIcon"
        id="multi_options_plus_icon"
        (click)="addMultiOption()"
        [hidden]="readOnly"
      ></fa-icon>
    </ng-template>

  </div>

  <div class="questionsFormActionsContainer">
    <button *ngIf="!(data.action === 'view')" mat-stroked-button (click)="onSubmit()">
      {{ data.action === 'delete' ? 'Delete' : 'Save' }}
    </button>
  
    <button mat-stroked-button (click)="onExit()">
      {{ data.action === 'view' ? 'Close' : 'Cancel' }}
    </button>
  </div>

</form>
