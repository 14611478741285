import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { apiUrl } from 'src/environments/environment';
import { Invitation, InvitationStatus } from 'src/app/shared/models/models.index';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';
import { UserActivityService, ResourceType } from 'src/app/shared/services/user-activity/user-activity.service';
import { QueryOptions } from 'src/app/shared/models/models.index';

@Injectable({
  providedIn: 'root'
})
export class InvitationsBuilderService {

  constructor(
    private http: HttpClient,
    private httpError: HttpErrorService,
    private userActivityService: UserActivityService,
  ) { }

  saveInvitation(invitation: Invitation): Observable<Invitation> {
    return this.userActivityService.createResource(invitation, ResourceType.INVITATION);
  }

  getInvitation(id: number): Observable<Invitation> {
    return this.userActivityService.readResource(ResourceType.INVITATION, { resourceId: id });
  }

  getInvitations(): Observable<Invitation[]> {
    return this.userActivityService.readResource(ResourceType.INVITATION)
  }

  getInvitationsByAssesmentID(queryOptions: Pick<QueryOptions, 'assessmentID'>): Observable<Invitation[]> {
    return this.userActivityService.readResource(ResourceType.INVITATION, { queryOptions });
  }

  updateInvitation(id: number, invitation: Invitation): Observable<Invitation> {
    return this.userActivityService.updateResource(id, invitation, ResourceType.INVITATION);
  }

  deleteInvitation(id: number): Observable<any> {
    return this.userActivityService.deleteResource(id, ResourceType.INVITATION);
  }

  getInvitationStatuses(): Observable<InvitationStatus[]> {
    return this.http.get<InvitationStatus[]>(`${apiUrl}/invitation-statuses`)
      .pipe(catchError(err => this.httpError.handleError(err)));
  }
}
