/**
 * This module is responisble for constructing the canonical request used to sign a request. For reference, the details of
 * creating a canonical reqeust can be found here: https://docs.aws.amazon.com/general/latest/gr/sigv4-create-canonical-request.html.
 *
 * This module should work for creating a canonical reqeust for any AWS service.
 *
 * Gotchas:
 *
 * - Encoding matters!
 *   - URI
 *     - Most services uses a double encoding strategy, which means a URI like this '/documents and settings/'
 *       becomes this '/documents%2520and%2520settings/' not '/documents%20and%%20settings/'. Notice the double encoded percent.
 *     - S3, and possible some others, use a single encoded URI strategy
 *   - Query string 
 *     - If an '=' exists in a value of a query string, it must be double encoded, '%3D' -> '%253D'
 * - Order matters
 *   - Headers
 *     - The headers must be converted to lowercase and sorted by keys
 *   - Query String
 *     - The query string is sorted by character code point(case matters) first by keys then by value
 */

import * as url from 'url';
import { HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';

import { hexEncode, hash } from './encrypt';

export enum UriEncodingStrategy {
  Single,
  Double
}

export class CanonicalRequest {
  private method: string;
  private uri: string;
  private params: string;
  private headers: Map<string, string>;
  private payload: string;
  private uriEncoding: UriEncodingStrategy;

  constructor(request: HttpRequest<any>, uriEncoding: UriEncodingStrategy) {
    this.method = request.method.toUpperCase();
    this.uriEncoding = uriEncoding;
    this.uri = this.convertUrlToUriPath(request.url);
    this.params = this.convertQueryParamsToSortedEncodedString(request.params);
    this.headers = this.convertRequestHeadersToOrderedMap(request.headers);
    this.payload = request.body ? JSON.stringify(request.body) : '';
  }

  static default(request: HttpRequest<any>): CanonicalRequest {
    return new CanonicalRequest(request, UriEncodingStrategy.Double);
  }

  static withEncoding(request: HttpRequest<any>, uriEncoding: UriEncodingStrategy) {
    return new CanonicalRequest(request, uriEncoding); 
  }

  private shouldDoubleEncodeUri(): boolean {
    return this.uriEncoding === UriEncodingStrategy.Double;
  }

  private convertUrlToUriPath(requestUrl: string): string {
    let parseUrl = url.parse(requestUrl);
    let uri = parseUrl.path || '/';
    return this.shouldDoubleEncodeUri() ? uri.split('/').map(encodeURIComponent).join('/') : uri;
  }

  private encodeQueryParamValue(value: string): string {
    return encodeURIComponent(value).replace('%3D', '%253D');
  }

  private encodeQueryParamParts(key: string, params: HttpParams): string[] {
    let values = params.getAll(key) || [];

    return values.sort().map(value => encodeURIComponent(key) + '=' + this.encodeQueryParamValue(value));
  }

  private convertQueryParamsToSortedEncodedString(params: HttpParams): string {
    if (!params) {
      return '';
    }

    return params.keys()
      .sort()
      .reduce((encodedQueryParamParts: string[], key: string) => [...encodedQueryParamParts, ...this.encodeQueryParamParts(key, params)], [])
      .join('&');
  }

  private stringifyHeaderValues(key: string, headers: HttpHeaders): string {
    let headerValues = headers.getAll(key) || [];
    return headerValues.map(value => value.trim().replace(/\s+/g, ' ')).join(',');
  }

  private convertRequestHeadersToOrderedMap(headers: HttpHeaders): Map<string, string> {
    return new Map(headers.keys()
      .sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)
      .map(key => [key.toLowerCase(), this.stringifyHeaderValues(key, headers)]));
  }

  canonicalMethod(): string {
    return this.method;
  }

  canonicalUri(): string {
    return this.uri; 
  }

  canonicalQueryString(): string {
    return this.params;
  }

  canonicalHeaders(): string {
    let canonicalHeaderString = '';
    
    for (const [key, value] of this.headers.entries()) {
      canonicalHeaderString += key + ':' + value + '\n';  
    }

    return canonicalHeaderString;
  }

  signedHeaders(): string {
    return Array.from(this.headers.keys()).join(';');
  }

  build(): string {
    return [
      this.canonicalMethod(),
      this.canonicalUri(),
      this.canonicalQueryString(),
      this.canonicalHeaders(),
      this.signedHeaders(),
      hexEncode(hash(this.payload))
    ].join('\n');
  }
}
