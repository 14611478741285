import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Question, Response, ResponseTracking } from 'src/app/shared/models/models.index';
import { faCheckCircle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-paginated-questions-sidenav',
  templateUrl: './paginated-questions-sidenav.component.html',
  styleUrls: ['./paginated-questions-sidenav.component.css']
})
export class PaginatedQuestionsSidenavComponent implements OnChanges {
  @Input() questions!: Question[];
  @Input() questionNumber!: number;
  @Input() response!: Response | null;
  @Input() readOnly!: boolean;
  @Output() questionChange = new EventEmitter<number>();
  @Output() submitChange = new EventEmitter<void>();
  answeredRTs: (ResponseTracking | null)[] = [];
  circle = faCircle;
  checkCircle = faCheckCircle;
  ellipsis = faEllipsisH;

  get responseTrackings(): ResponseTracking[] {
    return this.response && this.response.ResponseTrackings ? this.response.ResponseTrackings : [];
  }

  ngOnChanges(): void {
    this.answeredRTs = this.questions.map(question => {
      const matchedRT = this.responseTrackings.find(rt => rt.QuestionID === question.QuestionID);
      return matchedRT ? matchedRT : null;
    });
  }

  passQuestionChange(questionNumber: number): void {
    this.questionChange.emit(questionNumber);
  }

  triggerSubmit(): void {
    this.submitChange.emit();
  }
}
