import * as crypto from 'crypto-js';

export function hash(message: string): crypto.lib.WordArray {
  return crypto.SHA256(message)
}

export function hmac(key: crypto.lib.WordArray | string, message: string): crypto.lib.WordArray {
  return crypto.HmacSHA256(message, key);
}

export function hexEncode(wordArray: crypto.lib.WordArray): string {
  return wordArray.toString();
}
