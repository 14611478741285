import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SnackbarHandlerService } from './snackbar-handler.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {
  constructor(
    private snackBarHandlerService: SnackbarHandlerService,
    private router: Router
  ) { }

  public handleError(e: HttpErrorResponse): Observable<never> {
    const errorMessage = `${e.status} error. Unable to process request.`;
    if (400 <= e.status && e.status <= 499) {
      localStorage.setItem('error', JSON.stringify(e));
      this.router.navigate(['error']);
    } else if (500 <= e.status && e.status <= 599) {
      this.snackBarHandlerService.openSnackBar(errorMessage);
    }
    return throwError(errorMessage);
  }
}
