import { Component, OnInit, ChangeDetectorRef, NgZone, OnDestroy } from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState , FormFieldTypes } from '@aws-amplify/ui-components';
import { Router } from '@angular/router/';
import { RouteService } from '../../../../shared/services/route/route.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  user: CognitoUserInterface | undefined;
  authState!: AuthState;
  formFields!: FormFieldTypes;


  constructor(
    private ref: ChangeDetectorRef,
    private routeService: RouteService,
    private router: Router,
    private zone: NgZone,
  ) {
    this.formFields = [
      {
        type: "given_name",
        label: "First Name"
      },
      {
        type: "family_name",
        label: "Last Name"
      },
      { type: "email" },
      { type: "password" }
    ];
  }

  ngOnInit() {
    onAuthUIStateChange((authState, authData) => {

      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();

      if (authState === 'signup') {
        this.zone.run(() => this.router.navigate(['/register']));
      } else if (authState === 'signedin') {
        this.zone.run(() => this.router.navigate([`${this.routeService.redirectUrl}`]))
      }
    });

  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

}
