export enum Colors {
  Primary = 1,
  PrimaryDark = 2,
  Secondary = 3,
  SecondaryDark = 4,
  SecondaryAlt = 5
}

export const ColorValues = {
  1: '#3F86CC',
  2: '#0C2F87',
  3: '#EDEDEC',
  4: '#505050',
  5: 'gray',
};
