import { cognitoConfig } from 'src/environments/environment'

export const awsConfig = {
  apiVersion: '2016-04-18',
  region: 'us-east-1'
}

export const userAttributes = ['given_name', 'family_name', 'email']

export const params = { UserPoolId: cognitoConfig.aws_user_pools_id }
