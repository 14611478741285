import { Component } from '@angular/core';
import { Question } from 'src/app/shared/models/models.index';
import { QuestionDialogData } from '../../components/questions-modal/questions-modal.component';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.css']
})
export class CreateQuestionComponent {
  formData: QuestionDialogData = {question: null as unknown as Question, action: 'create'};
  
  constructor() { }

}
