import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NullishCoalescePipe } from './nullish-coalesce/nullish-coalesce.pipe';
import { UserStatusesPipe } from './user-statuses.pipe';

@NgModule({
 exports: [NullishCoalescePipe, UserStatusesPipe],
 declarations: [NullishCoalescePipe, UserStatusesPipe],
 imports: [
    CommonModule
 ]
})

export class PipesModule {
	static forRoot() {
		return {
			ngModule: PipesModule,
			providers: [],
		};
	}
}
