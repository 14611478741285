import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Question } from "src/app/shared/models/models.index";
import { QuestionsBuilderService } from "../../questions-builder.service";
import { QuestionsModalComponent } from "../questions-modal/questions-modal.component";
import { QuestionDialogData } from "../questions-modal/questions-modal.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
	selector: "app-questions-list",
	templateUrl: "./questions-list.component.html",
	styleUrls: ["./questions-list.component.css"]
})
export class QuestionsListComponent implements OnInit, OnDestroy, AfterViewInit {

	displayedQuestionData: string[] = [
		"QuestionID",
		"QuestionName",
		"QuestionDescription",
		"QuestionTypeName",
		"Actions"
	];
	subscription: Subscription = new Subscription();

  public questions = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


	constructor(
		private questionsBuilderService: QuestionsBuilderService,
		public dialog: MatDialog,
	) {}

	ngOnInit(): void {
		this.getQuestions();

    // Since we are getting the question type from accessing the column data via the string QuestionType.QuestionTypeName,
    // angular is looking for QuestionType.name instead of QuestionType.QuestionTypeName.name
    // This will allow us to sort by Type
    this.questions.sortingDataAccessor = (item, property) => {
      if (property === 'QuestionType.QuestionTypeName') {
        return item.QuestionType.QuestionTypeName;
      } else {
        return item[property];
      }  
    };
	}

  ngAfterViewInit(): void {
    this.questions.sort = this.sort;
    this.questions.paginator = this.paginator;
  }

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onAction(
		question: Question,
		action: "create" | "view" | "edit" | "delete"
	): void {
		const data: QuestionDialogData = { question, action };
		const dialogRef = this.dialog.open(QuestionsModalComponent, {
			width: "750px",
			data
		});
		dialogRef.afterClosed().subscribe(() => this.getQuestions());
	}

  getQuestions(): void {
    this.subscription.add(
      this.questionsBuilderService
        .getQuestions()
        .subscribe(questions => {
          this.questions.data = questions;
        }
      )
    )
  }

 public applyFilter = (filterValue: any) => {
  filterValue = (filterValue.target as HTMLInputElement).value
  filterValue = filterValue.trim(); // Remove whitespace
  filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
  this.questions.filter = filterValue;
 }
}
