import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';

@Injectable({
  providedIn: 'root'
})
export class MonacoEditorService {

  constructor(
    private monacoLoaderService: MonacoEditorLoaderService
  ) { }

  getMonaco() {
    return this.monacoLoaderService.isMonacoLoaded$.pipe(
      filter(isLoaded => isLoaded),
      map(() => monaco),
    )
  }

  getMonacoLanguages() {
    return this.getMonaco().pipe(
      map(monaco => monaco.languages.getLanguages()),
    )
  }

}
