<div *ngIf="questions" class="paginatedQuestionsSidenav">
    <div class="questionNavs">
        <div 
            *ngFor="let question of questions; index as i" 
            class="questionNavButtonContainer"
        >
            <button
                mat-button
                (click)="passQuestionChange(i)"
            >{{i + 1}}. {{ question.QuestionName }}</button>
            <div *ngIf="response" class="questionNavIconContainer">
                <!-- if current question -->
                <fa-icon *ngIf="questionNumber === i; else secondBlock" class="questionNavIcon" [icon]="ellipsis"></fa-icon>
                <!-- else if saved question -->
                <ng-template #secondBlock>
                    <fa-icon *ngIf="answeredRTs[i]; else thirdBlock" class="questionNavIcon" [icon]="checkCircle"></fa-icon>
                </ng-template>
                <!-- else unsaved question -->
                <ng-template #thirdBlock>
                    <fa-icon class="questionNavIcon" [icon]="circle"></fa-icon>
                </ng-template>
            </div>
        </div>
    </div>
    <button *ngIf="!readOnly" mat-stroked-button (click)="triggerSubmit()">Submit</button>
</div>