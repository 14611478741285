import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Question } from 'src/app/shared/models/models.index';

export interface QuestionDialogData {
  question: Question,
  action: 'create' | 'view' | 'edit' | 'delete'
}
@Component({
  selector: 'app-questions-modal',
  templateUrl: './questions-modal.component.html',
  styleUrls: ['./questions-modal.component.css']
})
export class QuestionsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<QuestionsModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: QuestionDialogData) { }

  onClose(e: any): void {
    this.dialogRef.close(e);
  }
}
